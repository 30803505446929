import { IAppService, IStateConfig, IFxpAppContext, IServiceEndPoints, IRouteInfo, IPartnerBundle, IAppServiceWithSharedComponent, ISharedComponents, ISharedComponentConfig } from '@fxp/fxpservices';
import { appSettings } from './environments/common/appsettingsGenerator';
import { PartnerAppRegistrationService } from '@fxp/fxpservices';

export class CreateAngularSharedComponentApplication_routes implements IAppService, IAppServiceWithSharedComponent {
    getSharedComponentsInfo(): ISharedComponents {
      const userProfileSharedComponent: ISharedComponentConfig = {

generatedBundle: 'b3c6beb12d67-UserProfileAngularModule-Bundle',
        componentName: "createangularsharedcomponent-userprofilesharedcomponent", // Unique name of component in format <appname>.<componentname> in lower case.
        exposeAsExternalComponents: false, // Setting it to true will make this component as external component. Keep it false if you want to consume only in context of FxP.
      }

      const sharedComponentsInProject: ISharedComponents =  {
        appName: 'CreateAngularSharedComponent', // Name of the application. Use the same name what you have used in main.ts file with registerPartnerApp method.
        components: [userProfileSharedComponent], // List of ISharedComponentConfig. Add all the ISharedComponentConfig objects here.
        disableSharing: false, // Turing it off will disable the sharing and your partners will not be able to consume the components.
        sharedBundles: [] // Name of any shared bundle which you want to load with this component. Set empty array if you have none. With module chunking FxP build process will iself figure out the required bundles.
      }

      return sharedComponentsInProject;
    }

    getRoutes(fxpContext: IFxpAppContext): IRouteInfo {
      const sharedComponentTest: IStateConfig = {
        name: "userprofile",
        url: "/userprofile",

generatedBundle: 'b3c6beb12d67-UserProfileAngularModule-Bundle',
        data: {
          headerName: 'User Profile Test Component',
          breadcrumbText: 'User Profile Test Component',
          pageTitle: 'User Profile Test Component'
        },

      }
        const routeInfo: IRouteInfo = {
            sharedBundles: [],
            routes: [sharedComponentTest]
        }

        return routeInfo;
    }

    getServiceEndPoints(): Array<IServiceEndPoints> {
        return appSettings().serviceEndPoints;
    }

    getBundles(): IPartnerBundle[] {
        const baseUrl = appSettings().cdnBaseUrl;
        const bundle: IPartnerBundle = {
          name: 'CreateAngularSharedComponent-Bundle', //TODO: Please use the format {appname}-{bundleName} to ensure the name of the bundle is always unique.
          files: [
            `${baseUrl}/vendor.bundle.js`,
            `${baseUrl}/styles.bundle.js`,
            `${baseUrl}/main.bundle.js`,
          ],
          sequentialLoading: true,
        };

        return [bundle];
    }
  public getGeneratedBundles() {
    var baseUrl = '';
    var currentScriptUrl = document.currentScript['src'];
    if (currentScriptUrl.indexOf('exclude.inline.bundle') > 0) {
      currentScriptUrl = Error().stack.match(/(https?:\/\/[^ ]*)/g);
      baseUrl = currentScriptUrl
        .filter((item) => item.indexOf('localhost:5000') === -1)[0]
        .toLowerCase();
      baseUrl = baseUrl.substring(0, baseUrl.lastIndexOf('/'));
    } else {
      baseUrl = currentScriptUrl.substring(0, currentScriptUrl.lastIndexOf('/'));
    }
    if (baseUrl.endsWith("/")){
        baseUrl = baseUrl.substring(0, baseUrl.lastIndexOf('/'));
    }
    return [
            {
                name: 'b3c6beb12d67-UserProfileAngularModule-Bundle',
                files: [
                    baseUrl + '/styles.bundle.js',
                    baseUrl + '/UserProfileAngularModule.vendor.bundle.js',
                    baseUrl + '/UserProfileAngularModule.bundle.js'
                ],
                sequentialLoading: true           
            },

    ]
}
}

PartnerAppRegistrationService.registerLazyLoadedApp(CreateAngularSharedComponentApplication_routes, 'CreateAngularSharedComponent');
